<template>
  <div class="container-fluid">

    <!-- Toggle Button Row -->
    <div class="row">
      <div class="col-12">
        <div class="toggle-wrapper">
          <input class="toggle toggle-right"
                 id="toggle-requests"
                 name="toggle"
                 type="radio"
                 value="requests"
                 v-model="contentTab" />
          <label class="toggle-label"
                 for="toggle-requests">
            Requests
          </label>

          <input class="toggle toggle-left"
                 id="toggle-moderators"
                 name="toggle"
                 type="radio"
                 value="moderators"
                 v-model="contentTab"
                 checked />
          <label class="toggle-label"
                 for="toggle-moderators">
            Moderators
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <hr class="mb-3 mt-3">
      </div>
    </div>

    <!-- Content Section Row -->
    <div class="row">
      <div class="col">
        <transition name="component-fade" mode="out-in">
          <moderator-list v-if="contentTab === 'moderators'">
          </moderator-list>

          <request-list v-if="contentTab === 'requests'"
                        :requests="requests">
          </request-list>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  const ModeratorList = () =>
  import('@/components/user/moderator/ModeratorList');
  const RequestList = () => import('@/components/user/moderator/RequestList');

  export default {
    name       : 'Moderator',
    components : {
      ModeratorList,
      RequestList,
    },
    data() {
      return {
        contentTab : 'requests',
        requests   : [],
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/moderator";
</style>
